/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import {  Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'react-loading-skeleton/dist/skeleton.css';

import Table from '@material-ui/core/Table';
import { domainPath } from '../App';
import { downloadDataDumpReport } from '../api/api';
import {
    wrap,
    subHeading,
    fieldRow,
    mainContent,
    dataTable,
    txtDetail
} from './styles';
import React, { useState } from 'react';
import { format } from 'date-fns';
import DynamicSearchFields from './DynamicSearchFields';
import DynamicTableListView from './DynamicTableListView';
import ClientSearchFilters from './ClientSearchFilters';
import * as ValidateRegex from '../Common/CommonRegex';

interface ClientSearchProps {
    clientList: any;
    searchFieldsList: any;
    onFormSubmit: (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        id_n: string
    ) => void;
    onSearchSubmit: (data: any, keySearch: any) => void;
    clearData: () => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    headerColor: string;
    user: any;
    uniqueField: string;
    configuredQuestionsList: any;
}

interface FormValues {
    first_name: string;
    last_name: string;
    client_code: string;
    ssn: string;
    id_n?: string;
    dob: string;
}

const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    client_code: '',
    ssn: '',
    id_n: '',
    dob: ''
};

const ClientSearch: React.FC<ClientSearchProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const history = useHistory();
    /** */
    let { clientList, searchFieldsList, user } = props;

    clientList = clientList.map((sec) => sec.sections);
    let searchList = searchFieldsList?.length > 0 ? searchFieldsList.map((cl) => cl.question) : [];
    const client =
        domainPath === 'adelphoiDDD'
            ? []
            : searchFieldsList.length > 0
            ? props.clientList.length > 0 &&
              props.clientList.map((q) =>
                  q.sections.map((list) => list.questions.filter((item) => searchList.includes(item.question)))?.flat()
              )
            : clientList.map((q) => q[0].questions);

            const downloadDump = async (e) => {
                const is_accessToken: any = props.user && props.user.user.accessToken;
                let data = {
                    "conditions": [],
                    "selections": [],
                    "time_filter": null,
                    "date_field": "",
                    "days_count": null,
                    "comparison": "",
                    "start_date": "",
                    "end_date": ""
                }
                await downloadDataDumpReport(data, is_accessToken, 1);
            };
   
    const style = makeStyles({
        titleItemRight: {
            color: 'white',
            height: 30,
            float: 'right',
            position: 'relative',
            transform: 'translateY(-10%)'
        }
    });
    const classes = style();

    const is_date = (date) => {
        let dateObj: any = new Date(date.replace(/-/g, '/'));
        if (ValidateRegex.dateRegex.test(date)) {
            let year = dateObj.getFullYear();
            let month = 1 + dateObj.getMonth();
            let datee = dateObj.getDate();
            let date1 = [month.toString().length > 1 ? month : `0${month}`, datee > 9 ? datee : `0${datee}`, year].join(
                '/'
            );
            return date1;
        }
        return date;
    };

    let dynamicInitialStateValues: any = {};
    let dynamicFormValues: any = {};
    typeof searchFieldsList !== 'object' &&
        searchFieldsList.map(
            (item) => ((dynamicInitialStateValues[item.question] = ''), (dynamicFormValues[item.question] = 'string'))
        );

    interface DFormValues {
        [key: string]: any;
    }

    const handleDatePicker = (date, name, callBack) => {
        let customDate = '';
        if (date) {
            var date2 = new Date(date),
                mnth = ('0' + (date2.getMonth() + 1)).slice(-2),
                day = ('0' + date2.getDate()).slice(-2);
            customDate = [date2.getFullYear(), mnth, day].join('/');
        } else {
            customDate = '';
        }
        if (!['NaN-aN-aN', 'NaN/aN/aN']?.includes(customDate)) {
            dynamicInitialStateValues[name] = customDate;
            if (callBack) {
                callBack(customDate);
            }
        }
    };

    return (
        <div css={wrap}>
            <div css={mainContent}>
            <React.Fragment>
                     <div css={fieldRow} style={{ justifyContent: 'center' }}>
                    <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                            marginRight: 10,
                            backgroundColor: props.headerColor,
                            color: '#fff'
                        }}
                        css={txtDetail}
                        onClick={downloadDump}
                    >
                        Download FirstMatch Data Report
                    </Button>
                </div>
                </React.Fragment>
                            
                <Formik
                    initialValues={searchFieldsList.length > 0 ? dynamicInitialStateValues : initialValues}
                    enableReinitialize
                    validate={(values) => {
                        const errors: FormikErrors<FormValues | DFormValues> = {};

                        if (
                            Object.keys(dynamicInitialStateValues)?.some((item) =>
                                dynamicInitialStateValues[item].includes('/')
                            )
                        ) {
                            values = Object.assign({}, values, dynamicInitialStateValues);
                        }
                        if (!Object.values(values).some((item) => item)) {
                            Object.keys(values).map((item) => (errors[item] = 'Enter either of the fields'));
                        }
                        return errors;
                    }}
                    onSubmit={async (values, helpers) => {
                        const code: any = values.client_code ? parseInt(values.client_code) : '';
                        const ssncode: any = values.ssn ? parseInt(values.ssn) : '';
                        const dob = values.dob ? format(new Date(values.dob), 'yyyy-MM-dd') : '';
                        const id_n = values.id_n ? values.id_n : '';

                        if (
                            Object.keys(dynamicInitialStateValues)?.some((item) =>
                                dynamicInitialStateValues[item].includes('/')
                            )
                        ) {
                            values = Object.assign({}, values, dynamicInitialStateValues);
                        }

                        Object.keys(values).map((item) => {
                            if (ValidateRegex.numberRegex.test(values[item])) {
                                values[item] = parseInt(values[item]);
                            }
                        });
                        history.push({ state: { version: undefined } });
                        setLoading(true);
                        if (searchFieldsList.length > 0) {
                            await props.onSearchSubmit(values, props.uniqueField);
                        } else {
                            await props.onFormSubmit(code, ssncode, values.first_name, values.last_name, dob, id_n);
                        }

                        setLoading(false);
                        // helpers.resetForm();
                    }}
                >
                    {({ values, handleSubmit, handleChange, setFieldValue }) => (
                        <form name="clientSearchForm" onSubmit={handleSubmit}>
                            <Table aria-label="clients table" css={dataTable}>
                                {searchFieldsList.length > 0 ? (
                                    <DynamicSearchFields
                                        searchFieldsList={searchFieldsList}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        headerColor={props.headerColor}
                                        values={values}
                                        dateHandler={handleDatePicker}
                                    />
                                ) : (
                                    <strong>
                                        There is no search field configured at FirstMatch Admin portal. Please communicate to FirstMatch
                                        admin to configure.
                                    </strong>
                                )}
                            </Table>
                        </form>
                    )}
                </Formik>
                <div>
                    {searchFieldsList.length > 0 && (
                        <React.Fragment>
                            <h1 css={subHeading} style={{ color: props.headerColor }}>
                                Client List
                            </h1>

                            <DynamicTableListView
                                searchFieldsList={searchFieldsList}
                                listDetails={client}
                                loading={loading}
                                is_date={is_date}
                                type="client"
                                uniqueField={props.uniqueField}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default ClientSearch;
