import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import App from './App';
import configureStore from './redux-modules/configureStore';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ConfirmationModal from './ConfirmationModal';

export const { store, persistor } = configureStore(createBrowserHistory());

const Root = () => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
  
    useEffect(() => {
      const onUpdate = (registration: ServiceWorkerRegistration) => {
        const waitingServiceWorker = registration.waiting;
  
        if (waitingServiceWorker) {
          setShowUpdateModal(true);
  
          const listener = (event: any) => {
            if (event.data && event.data.type === 'SKIP_WAITING') {
              waitingServiceWorker.removeEventListener('statechange', listener);
              window.location.reload();
            }
          };
  
          waitingServiceWorker.addEventListener('statechange', listener);
  
          return () => {
            waitingServiceWorker.removeEventListener('statechange', listener);
          };
        }
      };
  
      serviceWorkerRegistration.register({ onUpdate });
    }, []);
  
    const handleModalClose = (confirmed: boolean) => {
      if (confirmed) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistration().then(registration => {
            if (registration && registration.waiting) {
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
          });
        }
      }
      setShowUpdateModal(false);
    };
  
    return (
      <>
        <App />
        {/* <ConfirmationModal open={showUpdateModal} onClose={handleModalClose} /> */}
      </>
    );
  };

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router>
                    <Root />
                </Router>
            </MuiPickersUtilsProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
