import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import * as Types from '../api/definitions';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import * as family from '../redux-modules/family';
import * as dynamicclient from '../redux-modules/dynamicclient';
import FamilyDetailsFormStep from '../components/NewFamilyDetails/FamilyDetailsFormStep';
import { domainPath } from '../App';
import { getSoftSaveList } from '../api/api';

interface MatchParams {
    index: string;
    Rerefer: string;
    isEdit: string;
}
export interface DynamicNewFamilyContainerState {
    isLoading: boolean;
    error: any;
    hasError: boolean;
    isSuccess: boolean;
    isPredictionScores: boolean;
    isModifyVersion: string;
    isSavedFamilyLoaded: boolean;
    softSavedRecord: string[];
    tempDataId: string;
    isNoExcluCriteria: boolean;
    successMessage: string;
}
export interface PredictionScore {
    isPredictionScores: boolean;
}
export interface ModifyVersion {
    isModifyVersion: string;
}

export interface DynamicFamily {
    [key: string]: any;
}

type CallbackType = (result: boolean) => void;

export interface DynamicNewFamilyContainerProp extends ContainerProps<MatchParams>, WithSnackbarProps {
    saveFamily: (family: Types.DynamicFamily, page1FormCompleted?: boolean, excludePage2?: boolean) => void;
    insertFamily: (
        family: Types.DynamicFamily,
        action: string,
        is_accessToken: any,
        isPredictionScores: boolean
    ) => Promise<void>;
    softSaveFamily: (
        client: Types.DynamicFamily,
        is_accessToken: any
    ) => Promise<void>;
    partialSavedList: (
        configType: string,
        is_accessToken: string
    ) => Promise<void>;
    getSingleSavedFamily: (id: string, accessToken: string, methodType: string) => Promise<void>
    getLocations: (client_code: string, selected_program: string, is_accessToken: any) => Promise<void>;
    getPcr: (client_code: string, selected_program: string) => Promise<void>;
    saveLocationAndProgram: (
        selected_location: string,
        pcr_score: any,
        roc_score: any,
        isPredictionScores: boolean
    ) => Promise<void>;
    clearErrors: () => void;
    clearFamily: () => void;
    // getAvailablePrograms: () => Promise<void>;
    getConfiguredQuestions: (is_accessToken: any) => Promise<void>;
    // getReferral: () => Promise<void>;
    logo: () => Promise<void>;
    Referral: Types.Referral[];
    isEdit: string;
}

export class DynamicNewFamilyContainer extends React.Component<
    DynamicNewFamilyContainerProp,
    DynamicNewFamilyContainerState
> {
    constructor(props: DynamicNewFamilyContainerProp) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        let predictionScore = this.props.location?.state as PredictionScore;
        let modifyVersion = this.props.location?.state as ModifyVersion;
        return {
            isLoading: false,
            hasError: false,
            error: '',
            isSuccess: false,
            isPredictionScores: predictionScore?.isPredictionScores,
            isModifyVersion: modifyVersion?.isModifyVersion ? modifyVersion?.isModifyVersion : '',
            isSavedFamilyLoaded: false,
            softSavedRecord: [],
            tempDataId: '',
            progressMessage: '',
            isNoExcluCriteria: false,
            successMessage: ''
        };
    }

    GetQuestions = async () => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;

        try {
            await this.props.getConfiguredQuestions(is_accessToken);
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    GetSoftSaveList = async (callback: (result) => void) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;

        try {
            let list: any =  await this.props.partialSavedList('2', is_accessToken);
          callback(list?.response);
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    async componentDidMount() {
        this.props.match.params.isEdit === 'true' && this.props.closeSnackbar();
    }
    saveFamilyStep1 = async (family: Types.DynamicFamily, action: string) => {
        const { history } = this.props;
        this.props.clearErrors();
        this.setState({ isLoading: true, error: '' });
         const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
         if(this.state.tempDataId !== '') {
            family.temp_id = this.state.tempDataId;
        }
        const res: any = await this.props.insertFamily(family, action, is_accessToken, this.state.isPredictionScores);
        this.setState({ isLoading: false, error: '' });
        if (res !== null && res.data?.status === 'success') {
            let successMessage =  res.data.message;
            //this.setState({ isLoading: false, error: '' });
            //this.setState({ isSuccess: true, isSavedFamilyLoaded: false, successMessage: successMessage });
            this.setState({ successMessage: successMessage });
            await this.props.enqueueSnackbar(successMessage);
            history.push(`/${domainPath}/new-family/`);  
        } else {
            if (res !== null && res.data?.message === 'Family not registered') {
                this.props.enqueueSnackbar('Family not registered. Please update the family outcomes.');
            } else if (
                res !== null &&
                res.data?.message ===
                    'Family Code already exists. Data is not saved. Please use the existing family search to update any existing family data.'
            ) {
                this.setState({
                    error: res.data.message
                });
            } else {
                this.setState({
                    error: Array.isArray(res.data.response) ? res.data.response : res.data.message
                });
            }
        }
        return res.data;
    };

    redirectToNextStep = async () => {
        const { history } = this.props;
        await this.props.enqueueSnackbar(this.state.successMessage);
        history.push(`/${domainPath}/new-family/`);

    }

    getLocationsAndPcr = async (selected_program: string) => {
        const { family: familyState } = this.props;
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        if (!familyState || !familyState.family) {
            return false;
        }
        this.setState({ isLoading: true });
        await this.props.getPcr(familyState.family['family Code']!, selected_program);
        await this.props.getLocations(familyState.family['family Code']!, selected_program, is_accessToken);
        this.setState({ isLoading: false });
    };

    submitProgram = async (family: Types.DynamicFamily) => {
        if (!family.client_code) {
            this.props.enqueueSnackbar('Error. Family information is required to process this form.');
            return false;
        }
        try {
            this.setState({ isLoading: true });
           // await this.props.submitPrediction(family);
        } catch (error) {
            let errorMessage: string = 'An error occurred while saving.';
            if (error['referred_program']) {
                errorMessage = error['referred_program'][0];
            } else if (error.message) {
                errorMessage = error.message;
            }
            this.props.enqueueSnackbar(errorMessage);
        }
        this.setState({ isLoading: false });
        //this.props.clearFamily();
    };

    softSaveFamilyStep = async (family: Types.DynamicFamily ) => {
        this.props.clearErrors();
        this.setState({ isLoading: true, error: '' });
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        if(this.state.tempDataId !== '') {
            family._id = this.state.tempDataId;
        }
        
        const res: any = await this.props.softSaveFamily(family, is_accessToken);
       
        this.setState({ isLoading: false, error: ''});
        if (res !== null && res.data?.status === 'success') {
            this.setState({ isSuccess: true, isSavedFamilyLoaded: false, tempDataId: ''  });
            this.props.enqueueSnackbar('Partial information of family saved successfully. You may come back here to update information');
            window.location.reload();
        }  else {
            this.props.enqueueSnackbar('There is an error while saving information. Please try again.');
        }
        return res.data;
    };

    loadSavedFamily = async ( id: string, methodType: string, callback: CallbackType = () => {}) => {
        this.props.clearErrors();
        this.setState({ isLoading: true });
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const res: any = await this.props.getSingleSavedFamily(id, is_accessToken, methodType);
       this.setState({ isLoading: false });
     
        if (res !== null && res?.hasOwnProperty('_id') ) {
            this.setState({isSavedFamilyLoaded: true, tempDataId: res?._id})
            this.props.enqueueSnackbar('Family information loaded successfully.');
             callback(true);
          }  else if(res !== null )  {
            this.props.enqueueSnackbar('Family information deleted successfully.');
            window.location.reload();
          }
          else {
            this.props.enqueueSnackbar('There is an error while saving information. Please try again.');
        }
        return res?.data;
    };

    saveProgramAndLocation = async (selected_location: string, pcr_score: any, roc_score: any) => {
        const { family: familyState } = this.props;
        this.props.closeSnackbar();
        if (!familyState || !familyState.family) {
            this.props.enqueueSnackbar('Error. Family info not available.');
            return;
        }
        this.setState({ isLoading: true });
        await this.props.saveLocationAndProgram(selected_location, pcr_score, roc_score, this.state.isPredictionScores);
        this.setState({ isLoading: false });
        this.props.enqueueSnackbar('Report Generated');
    };

    saveFamilyStep2 = async (family: Types.DynamicFamily) => {
        try {
            this.setState({ isLoading: true });
            this.props.saveFamily(family);
        } catch (error) {
            console.log(error);
            this.setState({ isLoading: false });
            this.props.enqueueSnackbar('An error occurred.' + error);
        }
    };

    render() {
        const {
            family: familyState,
            program: programState,
            referral: referralState,
            family: familyQuestionsState
        } = this.props;
        const { isSavedFamilyLoaded } = this.state;
        const referralList = (referralState && referralState.referralList) || [];
        const familyList = (familyState && familyState.familyList) || {};
        const configuredQuestionsList: any = (familyQuestionsState && familyQuestionsState.familyQuestionsList) || [];
        let partiallySavedFamilyQuestions: any = (familyQuestionsState && familyQuestionsState.partiallySavedFamilyQuestions) || [];
        const { index, Rerefer } = this.props.match.params;
        let currentFamily: Types.DynamicFamily;
        currentFamily = familyState ? familyState.family : Types.emptyDFamily;
        partiallySavedFamilyQuestions = (partiallySavedFamilyQuestions.sections && partiallySavedFamilyQuestions.sections) || [];
       
        return (
            <Switch>
                <Route
                    exact
                    path={
                        index
                            ? `/${domainPath}/existing-family/edit-details/:index&:isEdit&:Rerefer`
                            : `/${domainPath}/new-family`
                    }
                >
                    <FamilyDetailsFormStep
                        {...this.state}
                        isEdit={index ? 'true' : 'false'}
                        client_id={index ? familyList[index]._id : ''}
                        reReffer={Rerefer ? Rerefer : ''}
                        Referral={referralList}
                        user={this.props && this.props.user}
                        DynamicQuestions={
                            index
                                ? [
                                    ...familyList[index].sections.filter((sec) => sec.section !== 'Outcomes'),
                                    ...configuredQuestionsList.filter((sec) => sec.section === 'Re Type')
                                ] 
                                : isSavedFamilyLoaded ? partiallySavedFamilyQuestions.filter((sec) => sec.section !== 'Outcomes') :configuredQuestionsList.filter((sec) => sec.section !== 'Outcomes')
                        }
                        // DynamicQuestions={index ? Object.keys(clientList[index]).map((key, id) => clientList[index][key] && clientList[index][key].hasOwnProperty("section") ? clientList[index][key] : "") : configuredQuestionsList}
                        //family={currentFamily.model_program ? Types.emptyDFamily : currentFamily}
                        onFormSubmit={this.saveFamilyStep1}
                        GetQuestions={this.GetQuestions}
                        GetSoftSaveList={this.GetSoftSaveList}
                        getSingleSavedFamily={this.props.getSingleSavedFamily}
                        loadSavedFamily={this.loadSavedFamily}
                        errors={this.state.error}
                        kidCode={index}
                        onFormSave={this.softSaveFamilyStep}
                        isSavedFamilyLoaded={isSavedFamilyLoaded}
                        redirectToNextStep={this.redirectToNextStep}
                    />
                </Route>
            </Switch>
            
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        family: state.family,
        program: state.program,
        referral: state.referral,
        user: state.user,
        dynamicclient: state.dynamicclient
    };
};

const mapDispatchToProps = {
    saveFamily: family.actions.upsertClient,
    insertFamily: family.actions.insertDFamily,
    softSaveFamily: family.actions.softSaveFamily,
    partialSavedList: family.actions.partialSavedList,
    getSingleSavedFamily: family.actions.getSingleSavedFamily,
    getLocations: dynamicclient.actions.getLocations,
    getPcr: dynamicclient.actions.getPcr,
    saveLocationAndProgram: dynamicclient.actions.saveLocationAndProgram,
    clearErrors: family.actions.clearErrors,
    clearFamily: family.actions.clear,
    // getAvailablePrograms: program.actions.getAvailablePrograms,
    getConfiguredQuestions: family.actions.getConfiguredQuestions
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DynamicNewFamilyContainer));
