/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { AppState } from '../redux-modules/root';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, ErrorMessage, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/NoteAdd';
import {
    wrap,
    subHeading,
    fieldRow,
    selectField,
    mainContent,
    twoCol,
    inputField,
    label,
    txtDetail,
    backdrop
} from './styles';
import Dropdown from './Dropdown';
import { baseApiUrl } from '../api/api';
import * as Types from '../api/definitions';
import { useSelector } from 'react-redux';
import { domainPath } from '../App';
interface ProgramSelectionProps {
    client: Types.Client;
    programList: Types.Program[];
    onProgramSelect: (selected_program: string) => void;
    onLocationSelect: (selected_location: string, pcr_score: any, roc_score: any, callBack: (result: boolean) => void) => Promise<void>;
    submitPrediction: (client: Types.Client) => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
}

interface FormValues {
    Program: any;
    Confidence: string;
    Roc_confidence: string;
    client_selected_location: string;
}
const ProgramSelection: React.FC<ProgramSelectionProps> = (props) => {
    const [clientCode, setClientCode] = useState<string | null>(null);
    const history = useHistory();

    const location: any = useLocation();
    const appState = useSelector((state: AppState) => state.user && state.user.user);

    useEffect(() => {
        props.client?.program_type && props.onProgramSelect(props.client.program_type[0]);
    }, [props.client.program_type]);
    const programOptions = props.client.SuggestedPrograms
        ? props.client.SuggestedPrograms.map((p) => {
              return {
                  label: p,
                  value: p,
                  predicted: p === props.client.program_type?.toString()
              };
          })
        : [];

    const getInitialValues = () => {
        const { client } = props;
        let program: any = null;

        if (client.client_selected_program) {
            program = {
                label: client.client_selected_program,
                value: client.client_selected_program,
                predicted: client.client_selected_program === client.program_type?.toString()
            };
        }
        
        return {
            Program: program,
            Confidence: client.Confidence,
            Roc_confidence: client.Roc_confidence,
            client_selected_location: ''
        };
    };

    const onProgramChange = (program: any) => {
        props.onProgramSelect(program.value);
    };
    const loginPassToken: string = appState ? appState.accessToken + '/' : '';
    return (
        <div css={wrap}>
            <div css={mainContent}>
                <Backdrop css={backdrop} open={props.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h1 css={subHeading}>FirstMatch&reg; Prediction</h1>
                <Formik
                    initialValues={getInitialValues()}
                    validate={(values) => {
                         const errors: FormikErrors<FormValues> = {};
                        // if (!values.client_selected_location) {
                        //   errors.client_selected_location = "Required";
                        // }
                        return errors;
                    }}
                    enableReinitialize
                    onSubmit={async (values) => {
                        const clientCode = Object.keys(props.client).includes('New Client Code')
                            ? props.client['New Client Code']
                            : props.client['Client Code'];
                            
                        await props.onLocationSelect(
                            values.client_selected_location,
                            values.Confidence,
                            values.Roc_confidence,
                            function(result) {
                                if(result){
                                    setClientCode(clientCode);
                                }
                            }
                        );
                        
                    }}
                >
                    {({ values, handleSubmit, handleChange }) => (
                        <form name="submitPredictionForm" onSubmit={handleSubmit}>
                            {clientCode && (
                                <div css={fieldRow} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                    Prediction program for client {props.client['First Name']}{' '}
                                    {props.client['Last Name']} has been created with client-id{' '}
                                    {Object.keys(props.client).includes('New Client Code')
                                        ? props.client['New Client Code']
                                        : props.client['Client Code']}
                                    .
                                    <a
                                        css={[txtDetail]}
                                        style={{ display: 'flex', marginRight: 15 }}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        onClick={() => history.push(`/${domainPath}/new-client`)}
                                        href={
                                            location.state.isPredictionScores

                                                ? `${baseApiUrl}/${domainPath}/index/${clientCode}/${loginPassToken}?is_for_scores=yes`
                                                : `${baseApiUrl}/${domainPath}/index/${clientCode}/${loginPassToken}`

                                        }
                                    >
                                        <PictureAsPdfIcon /> Download Report
                                    </a>
                                </div>
                            )}
                            {!clientCode && (
                                <div>
                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Program</label>
                                        </div>
                                        <div css={twoCol}>
                                            <Dropdown
                                                name="Program"
                                                options={programOptions}
                                                onChange={onProgramChange}
                                                defaultValue={programOptions.find(
                                                    (p) => p.value === props.client.client_selected_program
                                                )}
                                                value={values.Program || null}
                                                
                                            />
                                            
                                        </div>
                                    </div>

                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Program Completion Likelihood</label>
                                        </div>
                                        <div css={twoCol}>
                                            <input
                                                type="text"
                                                readOnly
                                                name="Confidence"
                                                css={inputField}
                                                placeholder=""
                                                value={values.Confidence === null ? '' : values.Confidence}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage component="span" name="Confidence" />
                                        </div>
                                    </div>
                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Remain Out of care Likelihood</label>
                                        </div>
                                        <div css={twoCol}>
                                            <input
                                                type="text"
                                                readOnly
                                                name="roc_confidence"
                                                css={inputField}
                                                placeholder=""
                                                value={values.Roc_confidence === null ? '' : values.Roc_confidence}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage component="span" name="roc_confidence" />
                                        </div>
                                    </div>
                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Location</label>
                                        </div>
                                        <div css={twoCol}>
                                            <select
                                                css={selectField}
                                                name="client_selected_location"
                                                value={values.client_selected_location}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                {props.client.SuggestedLocations &&
                                                    props.client.SuggestedLocations.map((loc) => (
                                                        <option key={loc} value={loc}>
                                                            {loc}
                                                        </option>
                                                    ))}
                                            </select>
                                            <ErrorMessage component="span" name="client_selected_location" />
                                        </div>
                                    </div>
                                    <div css={fieldRow} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Button type="submit" size="large" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </form>
                    )}
                </Formik>
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default ProgramSelection;
