import React from 'react';
import { connect } from 'react-redux';
import * as Types from '../api/definitions';
import { ContainerProps } from './Container';
import LoginPage from '../components/Login';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import * as user from '../redux-modules/user';
import * as dynamicclient from '../redux-modules/dynamicclient';
import * as family from '../redux-modules/family';
import { AppState } from '../redux-modules/root';
import { domainPath } from '../App';

export interface LoginContainerState {
    isLoading: boolean;
    error: string;
    hasLoginError: boolean;
}
export interface LoginContainerProp extends ContainerProps, WithSnackbarProps {
    login: (credential: any) => Promise<void>;
    preLogin: (email: string) => Promise<void>;
    getConfiguredQuestions: (is_accessToken: any) => Promise<void>;
    getConfiguredFamilyQuestions: (is_accessToken: any) => Promise<void>;
}

export class LoginContainer extends React.Component<LoginContainerProp, LoginContainerState> {
    constructor(props: LoginContainerProp) {
        super(props);
        // const isLoggedIn: any = props.user && props.user.user;
        if (localStorage.refreshToken) {
            this.props.history.push(`/${domainPath}/existing-client`);
        }
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            hasLoginError: false,
            error: ''
        };
    }

    onLogin = async (email: string, password: string) => {
        const { history } = this.props;
        this.setState({ isLoading: true });
        if (!email) {
            return false;
        }
        const credentials: Types.Credential = {
            email: email,
            password: password,
            domain: domainPath
        };
        this.setState({ isLoading: true });
        try {
            const res: any = await this.props.login(credentials);
            const pwd_updated = this.props.user && this.props.user.user && this.props.user.user.is_pwd_updated;
            const pwd_expired = this.props.user && this.props.user.user && this.props.user.user.is_pwd_expired;
            const is_configured: any =
                this.props.user && this.props.user.user && this.props.user.user.is_fully_configured;
            const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
            const org_type: any = this.props.user && this.props.user.user.org_type;

            if (res.status === 'success') {
                localStorage.setItem('refreshToken', res && res.response.token);
                localStorage.setItem('user_role', res && res.response.role_type);
                localStorage.removeItem('forgotPassword');
                await this.props.getConfiguredQuestions(is_accessToken);
                if (org_type === 3) {
                    await this.props.getConfiguredFamilyQuestions(is_accessToken);
                }
                this.setState({ isLoading: false });
                if (pwd_expired) {
                    history.push(`/${domainPath}/changepassword`);
                } else if (pwd_updated) {
                    history.push(
                        is_configured !== true ? `/${domainPath}/welcomepage` : `/${domainPath}/existing-client`
                    );
                } else {
                    history.push(`/${domainPath}/changepassword`);
                }
            } else {

                const error = res.message ? res.message : 'Something went wrong. Please try again later. ';
                this.setState({
                    error,
                    hasLoginError: true,
                    isLoading: false
                });
                return;
            }
        } catch (e) {
            console.log(e, 'error');
            const error = e.data
                ? e.data.message
                    ? e.data.message
                    : 'Something went wrong. Please try again later. '
                : 'Something went wrong. Please try again later. ';
            this.setState({
                error,
                hasLoginError: true,
                isLoading: false
            });
            return;
        }
    };
    preLoginHandler = async () => {
        let email: any = this.props.user ? this.props.user && this.props.user.user.email : '';
        try {
            const res: any = await this.props.preLogin(email);
            if (res.status === 'success') {
                let error_message = localStorage.sessionOut === 'true' ? 'Your session has expired. You have been logged out.' : res.message;
                this.setState({
                    error: error_message
                });
                localStorage.setItem('sessionOut', 'false');
            } else {
                this.setState({
                    error: res.message,
                    hasLoginError: true
                });
            }
        } catch (e) {
            const error1: string = e.data
                ? e.data.message
                    ? e.data.message
                    : 'Something went wrong. Please try again later. '
                : 'Something went wrong. Please try again later. ';
            this.setState({
                error: error1,
                hasLoginError: true
            });
        }
    };

    render() {
        return (
            <React.Fragment>
               <LoginPage
                    onLogin={this.onLogin}
                    {...this.state}
                    user={this.props.user && this.props.user.user}
                    preLogin={this.preLoginHandler}
                />
            </React.Fragment>

        );
    }
}
const mapStateToProps = (state: AppState) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = {
    login: user.actions.login,
    preLogin: user.actions.preLogin,
    getConfiguredQuestions: dynamicclient.actions.getConfiguredQuestions,
    getConfiguredFamilyQuestions: family.actions.getConfiguredQuestions
};
export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(LoginContainer));
