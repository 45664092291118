
export class TraumaScoreService {
    static async addTraumaScore(question, id, state, updateLocalState, Persues_House_Score) {
        
        try {
            const TScore = Persues_House_Score.find((score) => score.Question === question.replace(/_/g, ' '));
            const selectedID = Array.isArray(id) ? id[0] : id;
            const isChecked = Array.isArray(id) ? id[1] : '';

            question = TScore?.related !== '' ? (state.visitedQuestion[question] === undefined ? question : TScore?.related) : question;

            let score = 0;

            if (TScore) {
                            if (TScore?.related !== '') {
                                if([undefined, 0].includes(state.visitedQuestion[question]) || selectedID?.toString() === '0') {
                                    score = 0;
                                } else {
                                    score = -state.client_form[TScore?.related]?.length + 1;
                                }
                            } else {
                                score = selectedID ? TScore.values[selectedID].id : 0;
                            }
                        }

            const clearPreviousData = TScore && TScore?.related && selectedID?.toString() === '1' && state.client_form[TScore?.related] !== undefined;

            const updatedTraumaScore = this.updateTraumaScore(TScore, question, score, isChecked, clearPreviousData, state, updateLocalState);

            return updatedTraumaScore;
        } catch (error) {
            console.error('Error in addTraumaScore:', error);
            throw error;
        }
    }

    static async updateTraumaScore(TScore, question, score, isChecked, clearPreviousData, state, updateLocalState) {
        let updatedTraumaScore = state.trauma_score;

        if (TScore?.multiselect) {
            if (isChecked) {
                updatedTraumaScore += TScore?.addValues ? Number(score) : (state.visitedQuestion[question] ? (state.trauma_score === 0 ? Number(score) : 0) : Number(score));
            } else {
                updatedTraumaScore -= TScore?.addValues ? Number(score) : ((state.client_form[question] && Number(score) === 0) ? 0 : (state.client_form[question].length === 2 ? state.visitedQuestion[question] : 0));
            }
        } else {
            updatedTraumaScore = score === undefined ? state.trauma_score : (state.visitedQuestion[question] ? state.trauma_score - state.visitedQuestion[question] + Number(score) : state.trauma_score + Number(score));
        }

        await updateLocalState({ trauma_score: updatedTraumaScore })
        let DynamicQuestions = [...state.DynamicQuestions];
        
        DynamicQuestions = DynamicQuestions.map((section, index) => {
            return {
                ...section,
                questions: section.questions.map((question) => {
                    if(question.question === 'Trauma Score') {
                        return {
                            ...question,
                            answer: clearPreviousData ? []: updatedTraumaScore
                        }
                    } else {
                        return question
                    }
                })
            }
           });
       
        await updateLocalState({
            client_form: {
                ...state.client_form,
                [`Trauma Score`]: updatedTraumaScore,
                [question]: clearPreviousData ? [] : state.client_form[question]
            },
            visitedQuestion: {
                ...state.visitedQuestion,
                [question]: score < 0 ? 0 : Number(score)
            },
            DynamicQuestions: DynamicQuestions
        })
        
        return updatedTraumaScore;
    }
}

